body {
  margin: 0;
  padding: 0;
  background: url(./app/background.webp) no-repeat 50% fixed;
  background-size: cover;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
}

main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  position: relative;
}

#ont-logo {
  aspect-ratio: 271/48;
  height: 48px;
  width: 271px;
  max-width: 1000px;
  margin: 1rem auto;
  -webkit-padding-end: 2rem;
  padding-inline-end: 2rem;
  -webkit-padding-start: 2rem;
  padding-inline-start: 2rem;
  box-sizing: content-box;
}

article {
  background: white;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 2px rgba(74, 74, 74, 0.3);
  box-shadow: 0 2px 2px rgba(74, 74, 74, 0.3);
  -ms-flex: 0 1 500px;
  flex: 0 1 500px;
  border: solid 2rem transparent;
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: calc(720px + 2rem);
  margin: 0 auto 2rem;
}

h2 {
  margin-bottom: 1rem;
}

form {
  width: 100%;
  -ms-flex: 1;
  flex: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.app-page__header {
  margin-bottom: 2rem;
}
