.form-ctrl__helper-end.sc-nano-input {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
  min-inline-size: 150px;
  font-size: var(--invalid-msg-font-size);
  color: var(--help-msg-color);
  font-style: italic;
}

.form-ctrl__wrapper {
  -ms-flex: 1 1 18% !important;
  flex: 1 1 18% !important;
}

.form-ctrl__helper-end.sc-nano-input ul {
  margin: 0;
  padding-left: 1rem;
}
